import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import TermsAndConditions from "./pages/TermsAndConditions";
import Privacy from "./pages/Privacy";
import UserDashboard from "./pages/UserDashboard";
import PortaDashboard from "./pages/DashboardPorta";
import DashboardAccount from "./pages/DashboardAccount";
import DepositDashboard from "./pages/DashboardDeposit";
import DepositHome from "./pages/DashboardDepositHome";
import DepositCard from "./pages/DashboardDepositCard";
import DepositInternal from "./pages/DashboardDepositInternal";
import HomeDashboard from "./pages/DashboardHome";
import ConvertDashboard from "./pages/DashboardChange";
import WithdrawDashboard from "./pages/DashboardWithdraw";
import WithdrawDashboardHome from "./pages/DashboardWithdrawHome";
import WithSender from "./pages/WithDashboardSend"
import PublicHeader from "./components/Header";
import DashboardHeader from "./components/Header2";
import DashboardTrans from "./pages/DashboardTrans"
import CookieBanner from "./components/CookieBanner";
import Footer from "./components/Footer";
import KycVerification from "./pages/KycVerification";
import Intercom from '@intercom/messenger-js-sdk';
import "./index.css";
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isKycVerified, setIsKycVerified] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem("jwt");
    setIsLoggedIn(!!token);

    if (token) {
      fetch("https://www.swapbits.io/auth/api/kyc/status", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error al verificar estado KYC");
          }
          return response.json();
        })
        .then((data) => {
          setIsKycVerified(data.enable);

          // setIsKycVerified(data.status);
        })
        .catch((error) => {
          // console.error("Error al obtener estado KYC:", error);
          setIsKycVerified(false);
        })
        .finally(() => {
          setTimeout(() => {
          setLoading(false); // Finaliza la pantalla de carga
            
          }, 2000);
        });
    }
    else {
      setTimeout(() => {
      setLoading(false);
        
      }, 2000);
    }
  }, []);

  const requireKyc = (element) => {
    if (!isLoggedIn) {
      return <Navigate to="/login" />;
    }
    console.log(isKycVerified)
    if (isKycVerified == "false") {
      return <Navigate to={`/verification`} />;
    }
    return element;
  };
  // console.log("kyc", isKycVerified)

  // useEffect(() => {
  //   Intercom({
  //     app_id: 'b376fn6r',
  //     user_id: 'user.id', // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
  //     name: 'user.name', // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
  //     email: 'user.email', // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
  //     created_at: Math.floor(new Date().getTime() / 1000), // Example: Replace with actual Unix timestamp
  //   });
  // }, []);

  if (loading) {
    // Pantalla de carga
    return (
      <div className="loading-screen">
      <video
        autoPlay
        loop
        muted
        className="loading-video"
      >
        <source src="assets/videos/loading.mp4" type="video/mp4" />
       
      </video>

        <div className="spinner"></div>
    </div>
      
    );
  }
  return (
    <Router>
      {isLoggedIn && (isKycVerified == "true") ? <DashboardHeader /> : <PublicHeader />}
      <div className={isLoggedIn && (isKycVerified == "true") ? "dashboard-main-content" : "main-content"}>
        <Routes>
          <Route path="/" element={isLoggedIn ? <HomeDashboard /> : <Home />} />
          <Route
            path="/login"
            element={isLoggedIn ? <Navigate to="/dashboard/home" /> : <Login />}
          />
          <Route
            path="/register"
            element={isLoggedIn ? <Navigate to="/dashboard/home" /> : <Register />}
          />
          <Route path="/verification" element={<KycVerification />} />
          <Route
            path="/dashboard/home"
            element={requireKyc(<HomeDashboard />)}
          />
          <Route
            path="/dashboard/billfold"
            element={requireKyc(<PortaDashboard />)}
          />
          <Route
            path="/dashboard/convert"
            element={requireKyc(<ConvertDashboard />)}
          />
          <Route
            path="/dashboard/deposit/crypto"
            element={requireKyc(<DepositDashboard />)}
          />
          <Route
            path="/dashboard/deposit/card"
            element={requireKyc(<DepositCard />)}
          />
          <Route
            path="/dashboard/deposit/internal"
            element={requireKyc(<DepositInternal />)}
          />
          <Route
            path="/dashboard/deposit"
            element={requireKyc(<DepositHome />)}
          />
          <Route
            path="/dashboard/withdraw"
            element={requireKyc(<WithdrawDashboardHome />)}
          />
          <Route
            path="/dashboard/withdraw/crypto"
            element={requireKyc(<WithdrawDashboard />)}
          />
          <Route
            path="/dashboard/withdraw/internal"
            element={requireKyc(<WithSender />)}
          />
          <Route
            path="/dashboard/movements"
            element={requireKyc(<DashboardTrans />)}
          />
          <Route
            path="/dashboard/account"
            element={requireKyc(<DashboardAccount />)}
          />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <CookieBanner />
        {!isLoggedIn && <Footer />}
      </div>
    </Router>
  );
};

export default App;
