import React, { useState, useEffect } from "react";
import "../styles/DashboardChange.css";

const DashboardChange = () => {
  const [fromCurrency, setFromCurrency] = useState(""); // Moneda inicial
  const [toCurrency, setToCurrency] = useState(""); // Moneda de destino
  const [balances, setBalances] = useState({}); // Almacena los saldos dinámicos
  const [currencies, setCurrencies] = useState([]); // Monedas disponibles dinámicamente
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [isCalculating, setIsCalculating] = useState(false);
  const [error, setError] = useState(null);
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    fetchWalletInfo(); // Obtener los saldos al cargar el componente
  }, []);

  useEffect(() => {
    if (fromCurrency === toCurrency) {
      setError("La moneda de origen debe ser diferente a la de destino.");
      return;
    }
    if (fromAmount && fromCurrency && toCurrency) {
      if (parseFloat(fromAmount) > (balances[fromCurrency] || 0)) {
        setError("La cantidad ingresada excede tu saldo disponible.");
        return;
      }
      setIsCalculating(true);
      setError(null); // Limpiar errores previos
      setToAmount(""); // Limpiar temporalmente el resultado
      fetchExchangeRate(fromCurrency, toCurrency, fromAmount);
    } else {
      setToAmount(""); // Restablecer si no hay entrada
      setError("");
      setIsCalculating(false);
    }
  }, [fromAmount, fromCurrency, toCurrency]);

  const fetchWalletInfo = async () => {
    try {
      const token = localStorage.getItem("jwt");
      if (!token) {
        console.error("Token no encontrado en localStorage.");
        return;
      }

      const response = await fetch("https://www.swapbits.io/auth/api/wallet/info", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      processPortfolio(data);
    } catch (error) {
      console.error("Error fetching wallet info:", error);
    }
  };

  const processPortfolio = (data) => {
    const aggregatedPortfolio = {};

    // Extraer las monedas y saldos desde los balances
    data.balances.forEach((balance) => {
      // if (balance.balance > 0) {
        aggregatedPortfolio[balance.symbol] = parseFloat(balance.balance);
      // }
    });

    setBalances(aggregatedPortfolio);
    setCurrencies(Object.keys(aggregatedPortfolio)); // Configurar monedas dinámicamente

    // Seleccionar la primera moneda disponible como predeterminada
    if (Object.keys(aggregatedPortfolio).length > 0) {
      setFromCurrency(Object.keys(aggregatedPortfolio)[0]);
    }
  };

  const fetchExchangeRate = async (from, to, amount) => {
    try {
      const response = await fetch(
        `https://www.swapbits.io/auth/api/quotes/exchange-rate?from=${from}&to=${to}`
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      const rate = parseFloat(data.rate);
      if (rate && amount) {
        setToAmount((parseFloat(amount) * rate).toFixed(2));
      }
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setToAmount("Error al calcular");
    } finally {
      setIsCalculating(false);
    }
  };

  const handleSwitch = () => {
    const tempCurrency = fromCurrency;
    setFromCurrency(toCurrency);
    setToCurrency(tempCurrency);

    const tempAmount = fromAmount;
    setFromAmount(toAmount);
    setToAmount("");
  };

  const handleCancel = () => {
    setIsPreview(false);
  };

  const handleConfirm = () => {
    setIsPreview(false);
    handleConvert();
  };
  

  const handleConvert = async () => {
    try {
      const token = localStorage.getItem("jwt");
      if (!token) {
        setError("No se encontró el token de autenticación.");
        return;
      }
  
      const body = {
        of: fromCurrency,
        a: toCurrency,
        amount: parseFloat(fromAmount)
      };
  
      const response = await fetch("https://www.swapbits.io/auth/api/wallet/convert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log("Conversión exitosa:", data);
      alert("Conversión completada con éxito.");
      // Actualizar los balances después de la conversión
      fetchWalletInfo();
    } catch (error) {
      console.error("Error realizando la conversión:", error);
      setError("No se pudo realizar la conversión. Intenta nuevamente.");
    }
  };

  const handlePreview = () => {
    if (fromCurrency === toCurrency) {
      setError("La moneda de origen debe ser diferente a la de destino.");
      return;
    }
    if (!fromAmount || parseFloat(fromAmount) <= 0) {
      setError("La cantidad debe ser mayor a 0.");
      return;
    }
    if (parseFloat(fromAmount) > (balances[fromCurrency] || 0)) {
      setError("La cantidad ingresada excede tu saldo disponible.");
      return;
    }
    setError(null);
    setIsPreview(true);
  };

  return (
    <div className="dashboard-change">
      <h1>Intercambio</h1>

      {isPreview ? (
        <div className="preview-container">
          <div className="exchange-rate">
            <p className="rate-label">Tipo de cambio</p>
            <p className="rate-value">1 {fromCurrency} = {toAmount / fromAmount} {toCurrency}</p>
          </div>
          <div className="amount-details">
            <p className="amount-label">Se te descontará</p>
            <p className="amount-value">{fromAmount} {fromCurrency}</p>
            <p className="amount-label">Se te acreditará</p>
            <p className="amount-value highlight">
              {toAmount} {toCurrency}
            </p>
          </div>
          <div className="action-buttons">
            <button onClick={handleCancel} className="cancel-button">
              Cancelar
            </button>
            <button onClick={handleConfirm} className="confirm-button">
              Confirmar
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            <label>De:</label>
            <span className="available">Disponible: {balances[fromCurrency] || 0}</span>
          </div>

          <div className="input-container">
            <div className="menu-container">
              <select
                id="from-currency"
                value={fromCurrency}
                onChange={(e) => setFromCurrency(e.target.value)}
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {currencies.map((currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
              </select>
              <input
                type="number"
                placeholder="Cantidad"
                class="custom-input"
                value={fromAmount}
                onChange={(e) => setFromAmount(e.target.value)}
              />
            </div>
          </div>

          <button className="arrow" onClick={handleSwitch}>
            ⇅
          </button>

          <div className="row">
            <label>A:</label>
          </div>

          <div className="input-container">
            <div className="menu-container">
              <select
                id="to-currency"
                value={toCurrency}
                onChange={(e) => setToCurrency(e.target.value)}
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {currencies.map((currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Cantidad calculada"
                class="custom-input"
                value={isCalculating ? "Calculando..." : toAmount || ""}
                readOnly
              />
            </div>
          </div>

          {error && <p className="error-message">{error}</p>}

          <button onClick={handlePreview} className="preview-button">
            Confirmar previsualización
          </button>
        </>
      )}
    </div>
  );
};

export default DashboardChange;
