import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setShowBanner(true);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem('cookiesAccepted', 'all');
    setShowBanner(false);
  };

  const handleAcceptRequired = () => {
    localStorage.setItem('cookiesAccepted', 'required');
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div style={styles.banner}>
        <div style={styles.textContainer}>
          <p style={styles.text}>
            Usamos cookies y tecnologías similares para proporcionar funciones esenciales, mejorar la experiencia del usuario y ofrecer contenido relevante a tus intereses. Consulta nuestra{' '}
            <a href="/cookies-policy" style={styles.link}>
              política de cookies
            </a>{' '}
            para más detalles.
          </p>
        </div>
        <div style={styles.buttonContainer}>
          <button style={{ ...styles.button, ...styles.requiredButton }} onClick={handleAcceptRequired}>
            Solo cookies necesarias
          </button>
          <button style={{ ...styles.button, ...styles.acceptButton }} onClick={handleAcceptAll}>
            Aceptar todas
          </button>
        </div>
      </div>
    )
  );
};

const styles = {
  banner: {
    position: 'fixed',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '90%',
    maxWidth: '700px',
    backgroundColor: '#2c2c2c',
    color: '#ffffff',
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  textContainer: {
    textAlign: 'center',
    marginBottom: '15px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '1.6',
    margin: 0,
  },
  link: {
    color: '#007bff',
    textDecoration: 'underline',
  },
  buttonContainer: {
    display: 'flex',
    gap: '15px',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    flex: '1',
    maxWidth: '220px',
    padding: '12px 16px',
    borderRadius: '8px',
    border: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  acceptButton: {
    backgroundColor: '#555555', // Gris oscuro para "Aceptar todas"
    color: '#ffffff',
  },
  requiredButton: {
    backgroundColor: '#007bff', // Azul para "Solo cookies necesarias"
    color: '#ffffff',
  },
};

export default CookieBanner;
