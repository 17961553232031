import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import "../styles/DashboardPorta.css";
import axios from "axios";

const DashboardPortfolio = () => {
  const [data, setData] = useState([]);
  const [exchangeRates, setExchangeRates] = useState({});
  const [totalInEUR, setTotalInEUR] = useState(0);
  const [loadingBalances, setLoadingBalances] = useState(true);
  const [loadingExchangeRates, setLoadingExchangeRates] = useState(false);

  // Token JWT (reemplázalo por tu lógica para obtenerlo dinámicamente)
  const jwtToken = localStorage.getItem("jwt");

  // Configuración de axios para incluir el token JWT en los encabezados
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  // Función para obtener balances
  const fetchBalances = async () => {
    try {
      const response = await fetch("https://www.swapbits.io/auth/api/wallet/info", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      // Filtrar balances mayores a 0
      const res = await response.json()
      console.log(res.balances)
      const filteredData = res.balances.filter(
        (coin) => coin.balance > 0
      );
      console.log(filteredData)
      setData(filteredData);
    } catch (error) {
      console.error("Error al obtener balances:", error);
    } finally {
      setLoadingBalances(false);
    }
  };

  // Función para obtener tasas de intercambio
  function convertCryptos(data) {
    return {
        cryptos: data.reduce((result, item) => {
            result[item.symbol] = item.balance;
            return result;
        }, {})
    };
}

  const fetchExchangeRates = async () => {
    setLoadingExchangeRates(true);
    try {
      // Obtener todos los símbolos de las criptomonedas en una sola solicitud
      const portfolio = convertCryptos(data); // Unir símbolos separados por comas
      const convertTo = "EUR"; // Moneda de conversión
      // console.log(portfolio)
  
      // Realizar una única solicitud al backend
      const response = await fetch("https://www.swapbits.io/auth/api/convert-cryptos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(portfolio), // Enviar portafolio
      });

      const res = await response.json()

      // console.log(res)

  
      // Procesar las tasas de intercambio desde la respuesta
      const updatedRates = {};
      for (const symbol of data.map((coin) => coin.symbol)) {
      if (res[symbol] && res[symbol].eur) {
          updatedRates[symbol] = Number(res[symbol].eur);
      }
      const total = Object.values(updatedRates).reduce((sum, value) => sum + value, 0);
      // console.log(total)
      setTotalInEUR(total)

}

  
      // Actualizar las tasas en el estado
      setExchangeRates(updatedRates);
    } catch (error) {
      console.error("Error al obtener tasas de intercambio:", error);
    } finally {
      setLoadingExchangeRates(false);
    }
  };
  

  // Actualizar total en EUR
  // useEffect(() => {
  //   if (data.length > 0) {
  //     console.log(exchangeRates)
  //     // const total = exchangeRates.reduce(
  //     //   (sum, coin) =>
  //     //     sum + coin.balance * (coin.price?.eur || 0),
  //     //   0
  //     // );
  //     // setTotalInEUR(total);
  //   }
  // }, [data]);
  

  // Obtener datos al cargar el componente
  useEffect(() => {
    const fetchData = async () => {
      await fetchBalances();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      fetchExchangeRates();
    }
  }, [data]);

  // Preparar los datos para el gráfico
  const chartData = data.map((coin) => ({
    value: ((exchangeRates[coin.symbol] || 0)).toFixed(2),
    name: coin.symbol.toUpperCase(),
  }));

  // Configuración del gráfico
  const chartOptions = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} EUR ({d}%)",
    },
    legend: {
      orient: "vertical",
      right: 10,
      top: "center",
      data: data.map((coin) => coin.symbol.toUpperCase()),
      textStyle: {
        color: "#ffffff",
      },
    },
    series: [
      {
        name: "Monedas",
        type: "pie",
        radius: "50%",
        center: ["35%", "50%"],
        data: chartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgb(255, 255, 255)",
          },
        },
      },
    ],
  };

  return (
    <div className="content">
      {loadingBalances ? (
        <div className="loading">Cargando balances...</div>
      ) : (
        <>
          <div className="top-section">
            <section className="portfolio-summary">
              <div className="mb-15">
                <span className="title">Billetera</span>
              </div>
              <div className="portfolio-content flex">
                {loadingExchangeRates ? (
                  <div className="loading">Cargando tasas de intercambio...</div>
                ) : (
                  <ReactEcharts
                    option={chartOptions}
                    style={{ height: "300px", width: "100%" }}
                  />
                )}
              </div>
            </section>
            <div className="balance-total">
              <div className="card">
                <h2 className="title-bal font-m mb-15">Total disponible</h2>
                <div className="flex justify-between items-center">
                  <div className="total-container">
                    <span className="amount-title mr-5">
                      {loadingExchangeRates ? "Cargando Saldo..." : totalInEUR.toFixed(2) + " €"}
                    </span>
                    {/* <span className="base-currency-title"> EUR</span> */}
                    <a
                      className="deposit-text"
                      href="/dashboard/deposit/crypto"
                    >
                      Depositar
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="my-portfolio">
            <span className="title mb-15">Mis monedas</span>
            <div className="coins-container">
              <table className="coins-table">
                <thead>
                  <tr>
                    <th>Moneda</th>
                    <th>Balance</th>
                    <th>Valor en EUR</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((coin) => (
                    <tr key={coin.symbol}>
                      <td>
                        <div className="flex">
                          <span className="font-l font-semi-bold">
                            {coin.name}
                          </span>
                          <span className="font-xs secondary">
                            ({coin.symbol.toUpperCase()})
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-col">
                          <span className="font-m font-semi-bold">
                            {coin.balance}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-col">
                          <span className="font-m font-semi-bold">
                            {(
                              
                              (exchangeRates[coin.symbol] || 0)
                            ).toFixed(2)}
                          </span>
                          <span className="font-xs secondary"> EUR</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default DashboardPortfolio;
