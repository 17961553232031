import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/Header.css";

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("jwt");
        setIsLoggedIn(!!token); // Verifica si el token existe
    }, []);

    const handleMouseOver = (item) => {
        setIsMenuOpen(true);
        setActiveItem(item);
    };

    const handleMouseOut = () => {
        setIsMenuOpen(false);
        setActiveItem(null);
    };

    return (
        <header className="header">
            <div className="header-content">
                {/* Título */}
                <h1 className="header-title">
                    Swapbits
                </h1>
                {/* Menú horizontal */}
                <nav className="horizontal-menu">
                    <ul className="menu-list">
                        <li onMouseOver={() => handleMouseOver('home')} onMouseOut={handleMouseOut}>
                            <Link to="/" className={activeItem === 'home' ? 'active' : ''}>Inicio</Link>
                        </li>
                        <li onMouseOver={() => handleMouseOver('ayuda')} onMouseOut={handleMouseOut}>
                            <Link to="/ayuda" className={activeItem === 'ayuda' ? 'active' : ''}>Ayuda</Link>
                            {activeItem === "ayuda" && (
                                <ul className="vertical-menu">
                                    <li><Link to="/support">Soporte</Link></li>
                                    <li><Link to="/terms">Términos</Link></li>
                                </ul>
                            )}
                        </li>
                        {isLoggedIn ? (
                            <li onMouseOver={() => handleMouseOver('perfil')} onMouseOut={handleMouseOut}>
                                <Link to="/dashboard" className={activeItem === 'perfil' ? 'active' : ''}>Perfil</Link>
                                {activeItem === 'perfil' && (
                                    <ul className="vertical-menu">
                                        <li><Link to="/dashboard/billfold">Wallet</Link></li>
                                        <li><Link to="/dashboard/convert">Convert</Link></li>
                                        <li><Link to="/dashboard/deposit/crypto">Deposit</Link></li>
                                        <li><Link to="/dashboard/withdraw">Withdraw</Link></li>
                                        <li><Link to="/dashboard/movements">Movements</Link></li>
                                        <li><Link to="/dashboard/account">Account</Link></li>
                                        <li><button onClick={() => {
                                            localStorage.removeItem('jwt');
                                            window.location.href = '/login';
                                        }}>Logout</button></li>
                                    </ul>
                                )
                            }
                                
                            </li>
                        ) : (
                            <li onMouseOver={() => handleMouseOver('ingresar')} onMouseOut={handleMouseOut}>
                                <Link to="/register" className={activeItem === 'ingresar' ? 'active' : ''}>Ingresar</Link>
                                {activeItem === 'ingresar' && (
                                    <ul className="vertical-menu">
                                        <li><Link to="/register">Registro</Link></li>
                                        <li><Link to="/login">Iniciar Sesión</Link></li>
                                    </ul>
                                )}
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
