import React, { useState, useEffect } from "react";
import "../styles/DashboardDeposit.css"; // Asegúrate de tener estilos definidos
import { QRCodeCanvas } from "qrcode.react";

const Deposit = () => {
  const [cryptocurrencies, setCryptocurrencies] = useState([]);
  const [networkDetails, setNetworkDetails] = useState({});
  const [depositDetails, setDepositDetails] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  // Cargar datos de la API
  useEffect(() => {
    const fetchData = async () => {
      try {
      const token = localStorage.getItem("jwt");

        const response = await fetch("https://www.swapbits.io/auth/api/wallet/info", {
            method: "GET",
            headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
        const data = await response.json();
        console.log(data)

        setCryptocurrencies(data.cryptocurrencies || []);
        setNetworkDetails(data.networkDetails || {});
        setDepositDetails(data.depositDetails || {});
      } catch (error) {
        console.error("Error al cargar datos de la API:", error);
      }
    };

    fetchData();
  }, []);

  const handleNetworkClick = (network) => {
    setSelectedNetwork(network);
    setShowDetails(true);
  };

  return (
    <form className="deposit-container">
      {/* Selección de criptomoneda */}
      <section className="step-container">
        <h2 className="title">Selecciona una criptomoneda</h2>
        <select
          className="withdraw-select-coin"
          value={selectedCurrency}
          onChange={(e) => {
            setSelectedCurrency(e.target.value);
            setSelectedNetwork("");
            setShowDetails(false);
          }}
        >
          <option value="" disabled>Selecciona una moneda</option>
          {cryptocurrencies.map((crypto) => (
            <option key={crypto} value={crypto}>
              {crypto}
            </option>
          ))}
        </select>
      </section>

      {/* Selección de red */}
      {selectedCurrency && (
        <section className="step-container">
          <h2 className="title">Selecciona una red</h2>
          <div className="options">
            {(networkDetails[selectedCurrency] || []).map((network) => (
              <button
                key={network}
                className={`network-button ${selectedNetwork === network ? "active" : ""}`}
                onClick={() => handleNetworkClick(network)}
                type="button"
              >
                {network}
              </button>
            ))}
          </div>
        </section>
      )}

      {/* Mostrar detalles de depósito */}
      {showDetails && selectedCurrency && selectedNetwork && (
        <section className="step-container">
          <h2 className="title">Detalles de Depósito</h2>
          <div className="details">
            <p><strong>Criptomoneda:</strong> {selectedCurrency}</p>
            <p><strong>Red:</strong> {selectedNetwork}</p>
            <p><strong>Dirección:</strong> {depositDetails[selectedNetwork]?.address || "No disponible"}</p>
            <QRCodeCanvas
              value={depositDetails[selectedNetwork]?.address || ""}
              size={150}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
            />
            <p><strong>Monto mínimo:</strong> 
  {depositDetails[selectedNetwork]?.minAmount
    ?.find((min) => min.currency === selectedCurrency)
    ?.amount || "No disponible"} {selectedCurrency}
</p>


            <p><strong>Confirmaciones necesarias:</strong> {depositDetails[selectedNetwork]?.confirmations || "0"}</p>
            <button
              className="copy-button"
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(depositDetails[selectedNetwork]?.address || "");
                alert("Dirección copiada al portapapeles");
              }}
            >
              Copiar Dirección
            </button>
          </div>
        </section>
      )}
    </form>
  );
};

export default Deposit;
