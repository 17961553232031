// DashboardHome.jsx
import React, { useState, useEffect } from "react";
import chroma from "chroma-js";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import "../styles/DashboardHome.css";

ChartJS.register(ArcElement, Tooltip, Legend);
const DashboardHome = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletBalanceInUSDT, setWalletBalanceInUSDT] = useState(0);
  const [walletBalanceInEUR, setWalletBalanceInEUR] = useState(0);
  const [portfolio, setPortfolio] = useState({});
  const [quotes, setQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currency, setCurrency] = useState("EUR"); // Moneda seleccionada

  useEffect(() => {
    document.title = "Dashboard Home - Swapbits";
    fetchWalletInfo();
  }, []);

  useEffect(() => {
    fetchQuotes();
  }, [currency]); // Actualizar cotizaciones al cambiar la moneda

  const fetchWalletInfo = async () => {
    try {
      const token = localStorage.getItem("jwt");
      if (!token) {
        console.error("Token no encontrado en localStorage.");
        return;
      }

      const response = await fetch("https://www.swapbits.io/auth/api/wallet/info", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data)
      processPortfolio(data);
    } catch (error) {
      console.error("Error fetching wallet info:", error);
    }
  };

  const fetchQuotes = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`https://www.swapbits.io/auth/api/quotes?currency=${currency}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      setQuotes(data);
      calculateTotalInUSDT(data); // Calcular el saldo total en USDT
    } catch (error) {
      console.error("Error fetching quotes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const processPortfolio = (data) => {
    const aggregatedPortfolio = {};
  
    // Asumimos que 'data' tiene la estructura correcta de balances
    Object.entries(data.balances).forEach((value) => {
      const token = value["1"].symbol
      const amount = value["1"].balance
      console.log("token ",value["1"])
      if (!aggregatedPortfolio[token]) {
        aggregatedPortfolio[token] = 0;
      }
      aggregatedPortfolio[token] += parseFloat(amount);
    });
  
    console.log("ag",aggregatedPortfolio)
    // Filtrar monedas con balance > 0
    const filteredPortfolio = Object.fromEntries(
      Object.entries(aggregatedPortfolio).filter(([_, amount]) => amount > 0)
    );
  
    console.log("Portafolio Filtrado:", filteredPortfolio);
  
    
  
    convertPortfolio(filteredPortfolio);  // Llamamos a la conversión
  };
  
  

  const convertPortfolio = async (portfolio) => {
    console.log({"cryptos": portfolio})
    try {
      const response = await fetch("https://www.swapbits.io/auth/api/convert-cryptos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cryptos: portfolio }), // Enviar portafolio
      });
      console.log(response)
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
  
      // Actualizamos los estados con los datos procesados
      setWalletBalanceInEUR(
        Object.values(data).reduce((sum, values) => sum + parseFloat(values.eur), 0)
      );
      setWalletBalanceInUSDT(
        Object.values(data).reduce((sum, values) => sum + parseFloat(values.usdt), 0)
      );
      setPortfolio(Object.fromEntries(
        Object.entries(data).map(([key, value]) => [key, parseFloat(value.eur)])
      ))
      
  
      console.log("Datos Convertidos:", data);
    } catch (error) {
      console.error("Error al convertir el portafolio:", error);
    }
  };
  

  const calculateTotalInUSDT = (quotesData) => {
    let totalInUSDT = 0;
    Object.entries(portfolio).forEach(([token, amount]) => {
      const quote = quotesData.find((q) => q.currency === token);
      if (quote) {
        totalInUSDT += amount * parseFloat(quote.price); // Convertir usando el precio en USDT
      }
    });
    setWalletBalanceInUSDT(totalInUSDT);
  };

  const getPortfolioData = () => {
    const tokens = Object.keys(portfolio);
    const amounts = Object.values(portfolio);
  
    function generateDistinctColors(count) {
      // Usa una escala de colores para generar colores uniformemente distribuidos
      return chroma.scale(['#f94144', '#f3722c', '#f9c74f', '#90be6d', '#577590'])
        .mode('lch') // Usa un modo perceptivo para mejor contraste
        .colors(count); // Genera 'count' colores
    }
  
    const colors = generateDistinctColors(tokens.length);
  
    // Datos para el gráfico
    const data = {
      labels: tokens,
      datasets: [
        {
          data: amounts,
          backgroundColor: colors,
          hoverBackgroundColor: colors.map((color) =>
            chroma(color).alpha(0.7).css()
          ),
          borderColor: "#121212",
          borderWidth: 2,
        },
      ],
    };
  
    // Opciones del gráfico
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "right",
          labels: {
            color: "#ffffff", // Cambiar el color del texto de la leyenda
            font: {
              size: 14,
            },
          },
        },
        tooltip: {
          enabled: true,
          backgroundColor: "#333",
          titleColor: "#fff",
          bodyColor: "#fff",
          borderColor: "#fff",
          borderWidth: 1,
        },
      },
      layout: {
        padding: 20, // Espacio alrededor del gráfico
      },
    };
  
    return { data, options };
  };
  const portfolioData = getPortfolioData();

  return (
    <div className="dashboard-home">
      <div className="balance-container">
        <h2>Saldo Total</h2>
        {walletBalanceInUSDT > 0 && walletBalanceInEUR > 0 ? (
          <>
            <p>${walletBalanceInUSDT.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT</p>
            <p>€{walletBalanceInEUR.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} EUR</p>
          </>
        ) : (
          <p>Cargando saldos...</p>
        )}
      </div>

      <div className="portfolio-container">
        <h2>Portafolio (en EUR)</h2>
        <div className="chart-container">
      {Object.keys(portfolio).length > 0 ? (
        <Pie data={portfolioData.data} options={portfolioData.options} />
      ) : (
        <p style={{ color: "#ffffff", textAlign: "center" }}>
          Cargando datos del portafolio...
        </p>
      )}
    </div>

      </div>

      <div className="quotes-header">
        <h2>Cotizaciones</h2>
        <div className="currency-selector">
          <label>
            Ver Precios en
            <select value={currency} onChange={(e) => setCurrency(e.target.value)}>
              <option value="EUR">EUR</option>
              <option value="USD">USD</option>
              <option value="ARS">ARS</option>
            </select>
          </label>
        </div>
      </div>

      <div className="quotes-container">
        {isLoading ? (
          <p>Cargando cotizaciones...</p>
        ) : (
          <table className="quotes-table">
            <thead>
              <tr>
                <th>Moneda</th>
                <th>24h</th>
                <th>Precio Compra</th>
                <th>Precio Venta</th>
              </tr>
            </thead>
            <tbody>
              {quotes.map((quote, index) => (
                <tr key={index}>
                  <td>{quote.currency}</td>
                  <td>{quote.change24h}%</td>
                  <td>{parseFloat(quote.price * 1.015).toFixed(2)} {currency}</td>
                  <td>{parseFloat(quote.price * 0.995).toFixed(2)} {currency}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DashboardHome;
