// Header2.jsx
import React from "react";
import { Link } from "react-router-dom";
import "../styles/DashboardHeader.css";

const DashboardHeader = () => {
  return (
    <header className="dashboard-header">
      <div className="dashboard-header-content">
        <img
          src="../assets/images/icon.jpeg"
          className="dashboard-logo"
          alt="Logo"
        />
        <nav className="dashboard-horizontal-menu">
          <ul className="dashboard-menu-list">
            <li>
              <Link to="/dashboard/home">Inicio</Link>
            </li>
            <li>
              <Link to="/dashboard/billfold">Billetera</Link>
            </li>
            <li>
              <Link to="/dashboard/convert">Intercambio</Link>
            </li>
            <li>
              <Link to="/dashboard/deposit">Recibir</Link>
            </li>
            <li>
              <Link to="/dashboard/withdraw">Enviar</Link>
            </li>
            <li>
              <Link to="/dashboard/movements">Movimientos</Link>
            </li>
            <li>
              <Link to="/dashboard/account">Perfil</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="dashboard-logout-container">
        <button
          className="dashboard-logout-button"
          onClick={() => {
            localStorage.removeItem("jwt");
            window.location.href = "/login";
          }}
        >
          Cerrar Sesión
        </button>
      </div>
    </header>
  );
};

export default DashboardHeader;
