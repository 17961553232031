import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import "../styles/Login.css";

const Login = () => {
    useEffect(() => {
        document.title = "Login - Swapbits"; // Título dinámico
      }, []);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [sessionToken, setSessionToken] = useState(null); // Estado para el token de sesión
  const [code, setCode] = useState(""); // Estado para el código
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Estado para controlar el flujo de inicio de sesión
  const [errorMessage, setErrorMessage] = useState(""); // Estado para el mensaje de error
  const navigate = useNavigate(); // Inicializar useNavigate

  const handleSubmit = async (event) => {
    event.preventDefault(); // Evitar el comportamiento por defecto del formulario

    // Crear el objeto de datos a enviar
    const loginData = {
      email,
      password,
    };

    try {
      const response = await fetch("https://www.swapbits.io/auth/api/user/login", {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      if (!response.ok) {
        if (response.status === 401) {
          setErrorMessage("Email o contraseña incorrectos");
        } else {
          setErrorMessage("Error en el servidor. Por favor, inténtalo más tarde.");
        }
        return;
      }

      const data = await response.json();
      console.log("Inicio de sesión exitoso:", data);
      // Guardar el token de sesión y cambiar el estado
      setSessionToken(data.sessionToken);
      setIsLoggedIn(true); // Cambiar el estado a logueado
      setErrorMessage(""); // Limpiar cualquier error previo
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setErrorMessage("Error al conectar con el servidor.");
    }
  };

  const handleCodeSubmit = async (event) => {
    event.preventDefault();
  
    if (code.length !== 6) {
      setErrorMessage("El código debe tener exactamente 6 dígitos");
      return;
    }
  
    try {
      const response = await fetch("https://www.swapbits.io/auth/api/auth/verify-code", {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${sessionToken}`,
        },
        body: JSON.stringify({ email, code }),
      });
  
      if (!response.ok) {
        if (response.status === 400) {
          setErrorMessage("Código incorrecto o expirado");
        } else {
          setErrorMessage("Error en el servidor. Por favor, inténtalo más tarde.");
        }
        return;
      }
  
      const data = await response.json();
      localStorage.setItem("jwt", data.accessToken);
  
      console.log("Código verificado exitosamente:", data);
  
      // Actualiza el estado global de sesión y redirige
      
        setIsLoggedIn(true);
          window.location.href = "/dashboard/home";
     // Asegura que el token se almacene primero
    } catch (error) {
      console.error("Error al verificar el código:", error);
      setErrorMessage("Error al conectar con el servidor.");
    }
  };
  

  return (
    <div className="login-container">
      <div className="login-form">
        <h1>{isLoggedIn ? "Verificación de Código" : "Inicia Sesión"}</h1>
        {!isLoggedIn ? (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Ingresa tu email"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Contraseña:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Ingresa tu contraseña"
                required
                dir='ltr'
              />
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button type="submit" className="login-button">Iniciar Sesión</button>
          </form>
        ) : (
          <form onSubmit={handleCodeSubmit}>
            <div className="form-group">
              <label htmlFor="code">Código:</label>
              <input
                type="text"
                id="code"
                name="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Ingresa el código"
                required
              />
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button type="submit" className="verify-button">Verificar Código</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
