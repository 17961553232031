import React, { useState } from 'react';

import "../styles/DepositCard.css"


const CardDeposit = () => {
    const [crypto, setCrypto] = useState('Bitcoin');
    const [amount, setAmount] = useState('');
  
    const handleCryptoChange = (e) => setCrypto(e.target.value);
    const handleAmountChange = (e) => setAmount(e.target.value);
  
    const calculateCost = () => {
      const pricePerUnit = {
        Bitcoin: 30000, // Precio en EUR por unidad de Bitcoin
        Ethereum: 2000, // Precio en EUR por unidad de Ethereum
        Ripple: 1, // Precio en EUR por unidad de Ripple
      };
      return (pricePerUnit[crypto] * (amount || 0)).toFixed(2);
    };
  
    return (
      <main className="card-deposit-main">
        <div className="card-deposit-container">
          <div className="card-deposit-header">
            <h2 className="card-deposit-title">Depósito desde Tarjeta</h2>
            <p className="card-deposit-description">Agrega fondos fácilmente utilizando tu tarjeta de débito o crédito.</p>
          </div>
          <div className="card-deposit-body">
            <div className="card-deposit-input-group">
              <label htmlFor="cardNumber" className="card-deposit-label">Número de Tarjeta</label>
              <input 
                type="text" 
                id="cardNumber" 
                className="card-deposit-input" 
                placeholder="0000 0000 0000 0000" 
              />
            </div>
            <div className="card-deposit-input-group">
              <label htmlFor="expiryDate" className="card-deposit-label">Fecha de Expiración</label>
              <input 
                type="text" 
                id="expiryDate" 
                className="card-deposit-input" 
                placeholder="MM/AA" 
              />
            </div>
            <div className="card-deposit-input-group">
              <label htmlFor="cvv" className="card-deposit-label">CVV</label>
              <input 
                type="password" 
                id="cvv" 
                className="card-deposit-input" 
                placeholder="123" 
              />
            </div>
  
            <div className="card-deposit-row">
              <div className="card-deposit-input-group">
                <label htmlFor="crypto" className="card-deposit-label">Moneda a Comprar</label>
                <select 
                  id="crypto" 
                  className="card-deposit-select" 
                  value={crypto} 
                  onChange={handleCryptoChange}
                >
                  <option value="Bitcoin">Bitcoin</option>
                  <option value="Ethereum">Ethereum</option>
                  <option value="Ripple">Ripple</option>
                </select>
              </div>
  
              <div className="card-deposit-input-group">
                <label htmlFor="amount" className="card-deposit-label">Cantidad</label>
                <input 
                  type="number" 
                  id="amount" 
                  className="card-deposit-input" 
                  placeholder="Cantidad" 
                  value={amount}
                  onChange={handleAmountChange}
                />
              </div>
            </div>
  
            <p className="card-deposit-cost">Costo en EUR: <span>{calculateCost()} €</span></p>
  
            <button className="card-deposit-button">Procesar Depósito</button>
          </div>
        </div>
      </main>
    );
  };
  

export default CardDeposit;
