import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "../styles/Register.css";

const Register = () => {
    useEffect(() => {
        document.title = "Register - Swapbits";
    }, []);
    const [firstname, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleRegister = async () => {
        if (!termsAccepted) {
            setErrorMessage("Debe aceptar los términos y condiciones para registrarse.");
            return;
        }

        const userData = {
            firstname,
            email,
            password,
        };

        try {
            const response = await fetch("https://www.swapbits.io/auth/api/user/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                throw new Error("Error en la solicitud");
            }

            const data = await response.json();
            console.log("Registro exitoso:", data);

            if (data.status === "success") {
                setSuccessMessage("Registro exitoso! Redirigiendo a Login...");
                setErrorMessage("");
                setTimeout(() => {
                    navigate(`/login`); // Redirigir a la página de KYC con el userId
                }, 3000);
            } else {
                setErrorMessage(data.message || "Error en el registro");
            }
        } catch (error) {
            console.error("Error al registrar:", error);
            setErrorMessage("Error al conectar con el servidor");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleRegister();
    };

    return (
        <div className="register-container">
            <h2 className="register-title">Register</h2>
            {successMessage && <p className="register-success-message">{successMessage}</p>}
            {errorMessage && <p className="register-error-message">{errorMessage}</p>}
            <form onSubmit={handleSubmit} className="register-form">
                <label className="register-label register-username-label">
                    Username
                    <input
                        type="text"
                        name="firstname"
                        value={firstname}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        className="register-input register-username-input"
                    />
                </label>
                <label className="register-label register-email-label">
                    Email
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="register-input register-email-input"
                    />
                </label>
                <label className="register-label register-password-label">
                    Password
                    <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="register-input register-password-input"
                    />
                </label>
                <label className="register-terms-label">
                    <input
                        type="checkbox"
                        checked={termsAccepted}
                        onChange={(e) => setTermsAccepted(e.target.checked)}
                        className="register-checkbox"
                    />
                    Acepto los <a href="/terms" target="_blank" rel="noopener noreferrer" className="register-terms-link">términos y condiciones</a>
                </label>
                <button type="submit" className="register-button">Register</button>
            </form>
        </div>
    );
};

export default Register;
