import React, { useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import "../styles/DepositInternal.css"

const DepositInfoInternal = () => {
  const [userInfo, setUserInfo] = useState({ id: '', email: '' });
  const [copyMessage, setCopyMessage] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserInfo({
          id: decoded.sub.id || 'No ID found',
          email: decoded.sub.email || 'No email found',
        });
      } catch (error) {
        console.error('Error decoding JWT:', error);
      }
    }
  }, []);

  const handleCopy = () => {
    const textToCopy = `ID: ${userInfo.id}, Email: ${userInfo.email}`;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopyMessage('Copiado al portapapeles');
        setTimeout(() => setCopyMessage(''), 2000);
      })
      .catch((error) => {
        console.error('Error copying text:', error);
        setCopyMessage('Error al copiar');
      });
  };

  return (
    <div className="deposit-info-container">
      <h1 className="deposit-info-title">Información de Depósito Interno</h1>
      <div className="deposit-info-details">
        <p><strong>ID:</strong> {userInfo.id}</p>
        <p><strong>Email:</strong> {userInfo.email}</p>
        <button className="deposit-info-copy-button" onClick={handleCopy}>Copiar Información</button>
        {copyMessage && <p className="deposit-info-copy-message">{copyMessage}</p>}
      </div>
    </div>
  );
};

export default DepositInfoInternal;
