import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../styles/WithDashboardSender.css"
const SendCrypto = () => {
  const [balances, setBalances] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState('');
  const [amount, setAmount] = useState('');
  const [recipient, setRecipient] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchBalances = async () => {
      try {
        const token = localStorage.getItem('jwt');
        const response = await axios.get('https://www.swapbits.io/auth/api/wallet/info', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data && response.data.balances) {
          setBalances(response.data.balances);
        }
      } catch (error) {
        console.error('Error fetching balances:', error);
      }
    };

    fetchBalances();
  }, []);

  const handleSend = async () => {
    setError('');
    setSuccessMessage('');

    const selectedBalance = balances.find(b => b.symbol === selectedCrypto);
    if (!selectedBalance || selectedBalance.balance < parseFloat(amount)) {
      setError('Saldo insuficiente o criptomoneda no seleccionada.');
      return;
    }

    try {
      const token = localStorage.getItem('jwt');
      const response = await axios.post(
        'https://www.swapbits.io/auth/api/wallet/send-user',
        {
          amount: parseFloat(amount),
          crypto: selectedCrypto,
          to: recipient,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data && response.data.message) {
        setSuccessMessage('Transacción completada con éxito.');
        setAmount('');
        setRecipient('');
        setSelectedCrypto('');
      }
    } catch (error) {
      setError('Error al enviar la transacción: ' + error.response.data.message);
      console.error('Error:', error);
    }
  };

  return (
    <div className="send-crypto-container">
      <h1 className="send-crypto-title">Enviar Criptomoneda</h1>

      <div className="send-crypto-form">
        <div className="send-crypto-field">
          <label htmlFor="crypto" className="send-crypto-label">Seleccionar Criptomoneda</label>
          <select
            id="crypto"
            className="send-crypto-select"
            value={selectedCrypto}
            onChange={(e) => setSelectedCrypto(e.target.value)}
          >
            <option value="">-- Seleccionar --</option>
            {balances.map((balance) => (
              <option key={balance._id} value={balance.symbol}>
                {balance.name} ({balance.symbol}) - Saldo: {balance.balance}
              </option>
            ))}
          </select>
        </div>

        <div className="send-crypto-field">
          <label htmlFor="amount" className="send-crypto-label">Cantidad</label>
          <input
            type="number"
            id="amount"
            className="send-crypto-input"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Ingrese la cantidad"
          />
        </div>

        <div className="send-crypto-field">
          <label htmlFor="recipient" className="send-crypto-label">Destinatario</label>
          <input
            type="text"
            id="recipient"
            className="send-crypto-input"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            placeholder="ID o correo del destinatario"
          />
        </div>

        {error && <p className="send-crypto-error">{error}</p>}
        {successMessage && <p className="send-crypto-success">{successMessage}</p>}

        <button className="send-crypto-button" onClick={handleSend}>
          Enviar
        </button>
      </div>
    </div>
  );
};

export default SendCrypto;
