import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/TransactionList.css';

const TransactionList = () => {
  const [transactions, setTransactions] = useState([]);
  const [displayedTransactions, setDisplayedTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = localStorage.getItem('jwt');
        const response = await axios.get('https://www.swapbits.io/auth/api/wallet/transaction', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data && response.data) {
          setTransactions(response.data);
          setDisplayedTransactions(response.data.slice(0, itemsPerPage));
        } else {
          setTransactions([]);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, []);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const newDisplayedTransactions = transactions.slice(0, nextPage * itemsPerPage);
    setDisplayedTransactions(newDisplayedTransactions);
    setCurrentPage(nextPage);
  };

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const closeModal = () => {
    setSelectedTransaction(null);
  };

  const groupTransactionsByDate = () => {
    const grouped = { Hoy: [], Anteriores: [] };
    const today = new Date().toISOString().split('T')[0];

    transactions.forEach((transaction) => {
      const transactionDate = transaction.createdAt.split('T')[0];
      if (transactionDate === today) {
        grouped['Hoy'].push(transaction);
      } else {
        grouped['Anteriores'].push(transaction);
      }
    });

    return grouped;
  };

  const groupedTransactions = groupTransactionsByDate();

  return (
    <div className="transaction-list">
      {Object.entries(groupedTransactions).map(([group, transactions]) => (
        <div key={group} className="transaction-group">
          <h3 className="transaction-date">{group}</h3>
          {transactions.map((transaction) => (
            <div key={transaction.id} className="transaction-item" onClick={() => handleTransactionClick(transaction)}>
            <div className="transaction-info">
            <strong>
                  {transaction.type === 'withdrawal'
                    ? 'Retiro'
                    : transaction.type === 'deposit'
                    ? 'Depósito'
                    : transaction.type === 'send'
                    ? 'Envío'
                    : transaction.type === 'receive'
                    ? 'Tranferencia Interna Entrante'
                    : 'Conversión'}
                </strong>
  <p className="transaction-date-info">
    {new Date(transaction.createdAt).toISOString().split('T')[0] === new Date().toISOString().split('T')[0]
      ? `${new Date(transaction.createdAt).toLocaleTimeString('es-ES', { 
          hour: '2-digit', 
          minute: '2-digit', 
          timeZone: 'UTC' 
        })}`
      : `${new Date(transaction.createdAt).toLocaleDateString()}`}
  </p>
</div>
<div className="transaction-amount">
                {transaction.type === 'withdrawal' && (
                  <p style={{ color: 'red' }}>- {transaction.amount} {transaction.crypto}</p>
                )}
                {transaction.type === 'deposit' && (
                  <p style={{ color: 'green' }}>+ {transaction.amount} {transaction.crypto}</p>
                )}
                {transaction.type === 'send' && (
                  <p style={{ color: 'orange' }}>- {transaction.amount} {transaction.crypto}</p>
                )}
                {transaction.type === 'receive' && (
                  <p style={{ color: 'blue' }}>+ {transaction.amount} {transaction.crypto}</p>
                )}
                {transaction.type === 'convert' && (
                  <p>
                    {transaction.amountFrom} {transaction.cryptoFrom} → {transaction.amountTo.toFixed(2)}{' '}
                    {transaction.cryptoTo}
                  </p>
                )}
              </div>
            
          </div>
          ))}
        </div>
      ))}

      {displayedTransactions.length < transactions.length && (
        <button className="load-more-button" onClick={handleLoadMore}>
          Ver más
        </button>
      )}

{selectedTransaction && (
  <div className="modal">
    <div className="modal-container">
      <div className="modal-header">
        <div className="close-div">
          <button className="close-button" onClick={closeModal}>
            &times;
          </button>
        </div>
      </div>
      <div className="modal-content">
        <div className="modal-title-container">
          <span className="modal-title">
            {selectedTransaction.type === "convert"
              ? `${selectedTransaction.amountFrom} ${selectedTransaction.cryptoFrom} → ${selectedTransaction.amountTo.toFixed(2)} ${selectedTransaction.cryptoTo}`
              : `${selectedTransaction.amount} ${selectedTransaction.crypto}`}
          </span>
          <span className="modal-subtitle">
                  {selectedTransaction.type === "withdrawal"
                    ? "Retiro"
                    : selectedTransaction.type === "deposit"
                    ? "Depósito"
                    : selectedTransaction.type === "send"
                    ? "Envío"
                    : selectedTransaction.type === "receive"
                    ? "Recepción"
                    : "Conversión"}
                </span>
        </div>
      </div>
      <div className="modal-body">
        <div className="detail-item">
          <span className="detail-label">Fecha</span>
          <span className="detail-value">
            {new Date(selectedTransaction.createdAt).toLocaleString('es-ES', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              timeZone: 'UTC'
            }).replace(",", " -")}
          </span>
          </div>
{selectedTransaction.type === "convert" ? (
  <>
    <div className="detail-item">
      <span className="detail-label">Convertido de</span>
      <span className="detail-value">
        - {selectedTransaction.amountFrom} {selectedTransaction.cryptoFrom}
      </span>
    </div>
    <div className="detail-item">
      <span className="detail-label">Convertido a</span>
      <span className="detail-value">
        + {selectedTransaction.amountTo.toFixed(2)} {selectedTransaction.cryptoTo}
      </span>
    </div>
  </>
) : selectedTransaction.type === "send" ? (
  <>
  <div className="detail-item">
    <span className="detail-label">ID</span>
    <span className="detail-value">{selectedTransaction.id}</span>
  </div>
    <div className="detail-item">
      <span className="detail-label">Enviado a</span>
      <span className="detail-value">{selectedTransaction.nameTo} - {selectedTransaction.to}</span>
    </div>
    <div className="detail-item">
      <span className="detail-label">Monto</span>
      <span className="detail-value">
        - {selectedTransaction.amount} {selectedTransaction.crypto}
      </span>
    </div>
  </>
) : selectedTransaction.type === "receive" ? (
  <>
    <div className="detail-item">
      <span className="detail-label">Recibido de</span>
      <span className="detail-value"> {selectedTransaction.nameFrom} - {selectedTransaction.from}</span>
    </div>
    <div className="detail-item">
      <span className="detail-label">Monto</span>
      <span className="detail-value">
        + {selectedTransaction.amount} {selectedTransaction.crypto}
      </span>
    </div>
  </>
) : (
  <>
    <div className="detail-item">
      <span className="detail-label">Monto</span>
      <span className="detail-value">
        {selectedTransaction.amount} {selectedTransaction.crypto}
      </span>
    </div>
    <div className="detail-item">
      <span className="detail-label">Red</span>
      <span className="detail-value">{selectedTransaction.red}</span>
    </div>
    <div className="detail-item">
      <span className="detail-label">Hash</span>
      <span className="detail-value link">
        {selectedTransaction.hash
          ? `${selectedTransaction.hash.slice(0, 10)}...${selectedTransaction.hash.slice(-10)}`
          : "N/A"}
      </span>
    </div>
  </>
)}

      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default TransactionList;
