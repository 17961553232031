import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import '../styles/HomePage.css';

const HomePage = () => {
    const navigate = useNavigate();
    const carouselRef = useRef(null);
    const animationFrameIdRef = useRef(null); // Referencia para cancelar la animación
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        document.title = "Inicio - Swapbits";

        const carousel = carouselRef.current;

        // Duplicar elementos dinámicamente para el bucle
        // const items = carousel.children;
        // const totalItems = items.length;

        // for (let i = 0; i < totalItems; i++) {
        //     const clone = items[i].cloneNode(true);
        //     carousel.appendChild(clone);
        // }

        // const startCarousel = () => {
        //     const moveCarousel = () => {
        //         if (!isPaused && carousel) {
        //             carousel.scrollLeft += 1; // Desplazamiento suave

        //             // Comprobar si estamos al final de los elementos originales
        //             if (carousel.scrollLeft >= carousel.scrollWidth / 2) {
        //                 carousel.scrollLeft = 0; // Volver al inicio de forma continua
        //             }
        //             animationFrameIdRef.current = requestAnimationFrame(moveCarousel);
        //         }
        //     };
        //     moveCarousel();
        // };

        // startCarousel();

        return () => {
            // Cancelar la animación al desmontar el componente
            cancelAnimationFrame(animationFrameIdRef.current);
        };
    }, [isPaused]);

    const handleMouseEnter = () => {
        setIsPaused(true);
        cancelAnimationFrame(animationFrameIdRef.current); // Pausar la animación
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
        animationFrameIdRef.current = requestAnimationFrame(() => {}); // Reanudar
    };

    return (
        <div className="homepage-container">
            <div className="cta-header">
                <h2 className="cta-title">Comienza a intercambiar</h2>
                <p className="cta-description">
                    Regístrate hoy y experimenta la mejor manera de gestionar tus activos digitales.
                </p>
                <div className="cta-buttons">
                    <button className="small-button" onClick={() => navigate('/register')}>Registrarse</button>
                    <button className="small-button" onClick={() => window.alert('Proximamente...')}>Descargar Proximamente...</button>
                </div>
            </div>
            <video autoPlay loop muted className="background-video">
                <source src="assets/videos/video_home.mp4" type="video/mp4" />
            </video>

            <main className="homepage-main">
                <div className="features">
                    <h2>¿Por qué elegir Swapbits?</h2>
                    <p className="features-description">
                        Descubre las razones por las que nuestra plataforma es ideal para gestionar tus activos digitales.
                    </p>
                    {/* <div
                        className="custom-carousel"
                        ref={carouselRef}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className="carousel-item">
                            <img src="assets/images/image1.jpg" alt="Razón 1" />
                            <p>Plataforma segura y regulada</p>
                        </div>
                        <div className="carousel-item">
                            <img src="assets/images/image2.jpg" alt="Razón 2" />
                            <p>Interfaz intuitiva y amigable</p>
                        </div>
                        <div className="carousel-item">
                            <img src="assets/images/image3.jpg" alt="Razón 3" />
                            <p>Soporte para múltiples criptomonedas</p>
                        </div>
                    </div> */}
                </div>
            </main>
        </div>
    );
};

export default HomePage;
