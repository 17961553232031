import React from "react";
import "../styles/Footer.css";

const Footer = () => {
    return (
        <footer role="contentinfo" className="footer">
            <div className="footer__container">
                <div className="footer__columns">
                    {/* Columna 1 */}
                    <div className="footer__column">
                        <h6 className="footer__title">Acceso rápido</h6>
                        <a id="register-footer" href="/register" className="button button--main footer__button">
                            Regístrate
                        </a>
                        <a id="login-footer" href="/login" className="button button--secondary footer__button">
                            Iniciar sesión
                        </a>
                    </div>

                    {/* Columna 2 */}
                    <div className="footer__column">
                        <h6 className="footer__title">Más información</h6>
                        <a href="/fees" className="footer__link">Comisiones</a>
                        <a href="/corporate" className="footer__link">Empresas</a>
                    </div>

                    {/* Columna 3 */}
                    <div className="footer__column">
                        <h6 className="footer__title">Información legal</h6>
                        <a href="/privacy" className="footer__link">Política de privacidad</a>
                        <a href="/terms" className="footer__link">Términos y Condiciones</a>
                    </div>
                </div>
            </div>

            {/* Redes Sociales */}
            {/* <div className="footer__container footer__social-media">
                <div className="footer__social-links">
                    <a href="https://twitter.com/swapbits" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                        <img src="/assets/twitter.svg" alt="Twitter" />
                    </a>
                    <a href="https://www.instagram.com/swapbits" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                        <img src="/assets/instagram.svg" alt="Instagram" />
                    </a>
                    <a href="https://t.me/swapbits" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                        <img src="/assets/telegram.svg" alt="Telegram" />
                    </a>
                </div>
            </div> */}

            <div className="footer__container footer__legal">
                <p className="footer__legal-text">
                    © 2025 Swapbits Financial. Todos los derechos reservados.
                </p>
                <p className="footer__legal-text">
                    Swapbits Financial - Proveedores de Activos Virtuales (VASPs) registrado en Europa.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
