import React, { useState } from "react";
import "../styles/TwoFactor.css";
import axios from "axios";

const TwoFactorAuth = ({ onVerified }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [base32Secret, setBase32Secret] = useState("");
  const [authenticatorCode, setAuthenticatorCode] = useState(new Array(6).fill(""));
  const [emailCode, setEmailCode] = useState(new Array(6).fill(""));
  const [verificationStatus, setVerificationStatus] = useState("");

  const handleActivateClick = async () => {
    try {
      const response = await axios.post(
        "https://www.swapbits.io/auth/api/user/security/generate2fa",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setQrCode(response.data.qrcode);
      setBase32Secret(response.data.base32);
      setModalVisible(true);
    } catch (error) {
      console.error("Error generando 2FA:", error);
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setVerificationStatus("");
  };

  const handleCopySecret = () => {
    navigator.clipboard.writeText(base32Secret).then(() => {
      alert("Código copiado al portapapeles");
    });
  };

  const handleAuthenticatorCodeChange = (value, index) => {
    if (!/^[0-9]*$/.test(value)) return; // Solo permitir números

    const newCode = [...authenticatorCode];
    newCode[index] = value;

    setAuthenticatorCode(newCode);

    if (value && index < authenticatorCode.length - 1) {
      document.getElementById(`auth-code-input-${index + 1}`).focus();
    } else if (!value && index > 0) {
      document.getElementById(`auth-code-input-${index - 1}`).focus();
    }
  };

  const handleEmailCodeChange = (value, index) => {
    if (!/^[0-9]*$/.test(value)) return; // Solo permitir números

    const newCode = [...emailCode];
    newCode[index] = value;

    setEmailCode(newCode);

    if (value && index < emailCode.length - 1) {
      document.getElementById(`email-code-input-${index + 1}`).focus();
    } else if (!value && index > 0) {
      document.getElementById(`email-code-input-${index - 1}`).focus();
    }
  };

  
  

  const handleVerifyClick = async () => {
    try {
      const response = await axios.post(
        "https://www.swapbits.io/auth/api/user/security/verify2fa",
        {
          code: emailCode.join(""),
          token: authenticatorCode.join(""),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
  
      if (response.data.valid) {
        setVerificationStatus("Verificación exitosa");
        setTimeout(() => {
          setModalVisible(false);
          setVerificationStatus("");
      
          if (onVerified) {
            onVerified(); // Llama a la función pasada por props
          }
        }, 2000);
      }
       else {
        setVerificationStatus("Código incorrecto");
      }
    } catch (error) {
      console.error("Error verificando 2FA:", error);
      setVerificationStatus("Error verificando el código");
    }
  };
  

  return (
    <div>
      <h2 className="unique-title">Autenticación en 2 pasos (2FA)</h2>
      <button className="unique-action-button" onClick={handleActivateClick}>
        Activar
      </button>

      {isModalVisible && (
        <div className="unique-dialog-container">
          <div className="unique-close-dialog">
            <button
              className="unique-dialog-close-button"
              onClick={handleCloseModal}
            >
              ✖
            </button>
          </div>
          <div className="unique-dialog-content">
            <h3 className="unique-dialog-title">Escaneá el código QR</h3>
            <p className="unique-dialog-description">
              Usando tu aplicación de token seleccionada <b>(Authy o Google Authenticator)</b>
            </p>
            {qrCode && (
              <img
                className="unique-qr-code"
                src={qrCode}
                alt="QR Code"
              />
            )}
            <div className="unique-code-container">
              <span className="unique-code">{base32Secret}</span>
              <button className="unique-copy-button" onClick={handleCopySecret}>
                📋
              </button>
            </div>
            <div className="unique-email-code-section">
              <p className="unique-email-code-title">Código de email</p>
              <p className="unique-email-code-description">
                Ingresá el código que enviamos a tu correo.
              </p>
              <div className="unique-code-inputs">
                {emailCode.map((code, index) => (
                  <input
                    key={index}
                    id={`email-code-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={code}
                    onChange={(e) => handleEmailCodeChange(e.target.value, index)}
                    className="unique-code-input"
                  />
                ))}
              </div>
            </div>
            <div className="unique-authenticator-code-section">
              <p className="unique-authenticator-code-title">
                Código de autenticador
              </p>
              <p className="unique-authenticator-code-description">
                Ingresá el código generado por tu aplicación de 2FA.
              </p>
              <div className="unique-code-inputs">
                {authenticatorCode.map((code, index) => (
                  <input
                    key={index}
                    id={`auth-code-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={code}
                    onChange={(e) => handleAuthenticatorCodeChange(e.target.value, index)}
                    className="unique-code-input"
                  />
                ))}
              </div>
            </div>
            <button
              className="unique-verify-button"
              onClick={handleVerifyClick}
            >
              Verificar
            </button>
            {verificationStatus && (
              <p className="unique-verification-status">{verificationStatus}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TwoFactorAuth;
