import React, { useState, useEffect } from 'react';
import '../styles/report.css'
const WalletReport = () => {
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    const fetchWalletInfo = async () => {
      try {
        const token = localStorage.getItem('jwt');
        if (!token) {
          throw new Error('No JWT found. Please login again.');
        }

        const response = await fetch('https://www.swapbits.io/auth/api/wallet/transaction', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`Error fetching wallet info: ${response.status}`);
        }

        const data = await response.json();

        // Extrae las fechas de las transacciones
        const dates = data.map(transaction => new Date(transaction.createdAt));
        const minTransactionDate = new Date(Math.min(...dates));
        const maxTransactionDate = new Date(Math.max(...dates));

        setMinDate(minTransactionDate.toISOString().split('T')[0]);
        setMaxDate(maxTransactionDate.toISOString().split('T')[0]);
      } catch (err) {
        console.error(err);
        setError('Failed to fetch wallet info. Please try again later.');
      }
    };

    fetchWalletInfo();
  }, []);

  const handleGenerateReport = async () => {
    if (!startDate || !endDate) {
      setError('Please select a valid date range.');
      return;
    }

    if (new Date(startDate) < new Date(minDate) || new Date(endDate) > new Date(maxDate)) {
      setError(`Dates must be between ${minDate} and ${maxDate}.`);
      return;
    }

    try {
      const token = localStorage.getItem('jwt');
      if (!token) {
        throw new Error('No JWT found. Please login again.');
      }

      const response = await fetch(`https://www.swapbits.io/auth/api/wallet/create-report?dateFrom=${startDate}&dateTo=${endDate}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`Error generating report: ${response.status}`);
      }

      const data = await response.json();
      setSuccessMessage('Report generated successfully!');
      console.log('Report data:', data);
    } catch (err) {
      console.error(err);
      setError('Failed to generate report. Please try again later.');
    }
  };

  return (
    <div className="wallet-report-container">
      <h1 className="wallet-report-title">Generate Wallet Report</h1>
      {error && <p className="wallet-report-error" style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p className="wallet-report-success" style={{ color: 'green' }}>{successMessage}</p>}

      {minDate && maxDate ? (
        <div className="wallet-report-form">
          <p className="wallet-report-dates">Transactions available from {minDate} to {maxDate}</p>
          <div className="wallet-report-input-group">
            <label className="wallet-report-label">Start Date: </label>
            <input
              className="wallet-report-input"
              type="date"
              value={startDate}
              min={minDate}
              max={maxDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="wallet-report-input-group">
            <label className="wallet-report-label">End Date: </label>
            <input
              className="wallet-report-input"
              type="date"
              value={endDate}
              min={minDate}
              max={maxDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <button className="wallet-report-button" onClick={handleGenerateReport}>Generate Report</button>
        </div>
      ) : (
        <p className="wallet-report-loading">Loading transaction data...</p>
      )}
    </div>
  );
};

export default WalletReport;
