import React, { useEffect } from 'react';
import '../styles/TermsAndConditions.css';

const TermsAndConditions = () => {
    useEffect(() => {
        document.title = "Términos y Condiciones - Swapbits";
    }, []);

    return (
        <div className="terms-container">
            <h1 className="terms-title">Términos y Condiciones</h1>
            <p className="terms-date">
                Fecha de entrada en vigor: 05/01/2025 <br />
                Última actualización: 05/01/2025
            </p>

            <section>
                <h2 className="terms-subtitle">1. Introducción</h2>
                <p className="terms-text">
                    Bienvenido a Swapbits Financial. Estos Términos y Condiciones regulan el acceso y uso de nuestros servicios proporcionados a través de <a href="https://www.swapbits.io" className="terms-link">www.swapbits.io</a>. Al utilizar nuestra plataforma, aceptas cumplir con estos términos.
                </p>
            </section>
            <hr />
            
            <section>
                <h2 className="terms-subtitle">2. Identidad de la Empresa</h2>
                <ul className="terms-list">
                    <li><strong>Nombre Legal:</strong> Swapbits Financial</li>
                    <li><strong>Número de Registro:</strong> 0001132148</li>
                    <li><strong>Número de Licencia VASP:</strong> RDWW-1584</li>
                    <li><strong>Dirección:</strong> Bartycka 22B/21A, 00-716 Varsovia, Polonia</li>
                    <li><strong>Correo Electrónico:</strong> <a href="mailto:support@swapbits.io" className="terms-link">support@swapbits.io</a></li>
                </ul>
            </section>
            <hr />

            <section>
                <h2 className="terms-subtitle">3. Definiciones</h2>
                <ul className="terms-list">
                    <li><strong>Cripto-tokens:</strong> Representaciones digitales de valor basadas en blockchain.</li>
                    <li><strong>Plataforma:</strong> El sitio web y aplicaciones móviles de Swapbits.</li>
                    <li><strong>Usuario:</strong> Persona que utiliza los servicios de Swapbits.</li>
                    <li><strong>Transacción:</strong> Operaciones de compra, venta o intercambio realizadas en la plataforma.</li>
                    <li><strong>Cuenta:</strong> Registro personal del Usuario en la plataforma.</li>
                </ul>
            </section>
            <hr />

            <section>
                <h2 className="terms-subtitle">4. Registro de Usuario</h2>
                <ul className="terms-list">
                    <li>Los usuarios deben completar el proceso de verificación KYC para activar su cuenta.</li>
                    <li>Es responsabilidad del usuario mantener la confidencialidad de sus credenciales.</li>
                    <li>Swapbits puede suspender cuentas que infrinjan estos términos o las leyes aplicables.</li>
                </ul>
            </section>
            <hr />

            <section>
                <h2 className="terms-subtitle">5. Servicios Ofrecidos</h2>
                <ul className="terms-list">
                    <li>Compra y venta de criptomonedas.</li>
                    <li>Intercambio entre criptomonedas.</li>
                    <li>Wallets seguras para almacenamiento de criptoactivos.</li>
                </ul>
            </section>
            <hr />

            <section>
                <h2 className="terms-subtitle">6. Actividades Prohibidas</h2>
                <ul className="terms-list">
                    <li>Participar en actividades ilegales, como lavado de dinero o financiamiento del terrorismo.</li>
                    <li>Realizar intentos de hackeo, manipulación del sistema o uso fraudulento.</li>
                    <li>Usar scripts o bots para automatizar operaciones.</li>
                    <li>Transmitir virus, spyware u otro software malicioso.</li>
                    <li>Suplantar la identidad de otro usuario o actuar de forma engañosa.</li>
                </ul>
            </section>
            <hr />

            <section>
                <h2 className="terms-subtitle">7. Modificaciones y Suspensión del Servicio</h2>
                <p className="terms-text">
                    Swapbits se reserva el derecho de modificar, suspender o descontinuar cualquier servicio en cualquier momento. Las actualizaciones se realizarán para mejorar la funcionalidad o cumplir con cambios regulatorios.
                </p>
            </section>
            <hr />

            <section>
                <h2 className="terms-subtitle">8. Limitación de Responsabilidad</h2>
                <ul className="terms-list">
                    <li>Las criptomonedas son altamente volátiles. No somos responsables de pérdidas derivadas de fluctuaciones en el mercado.</li>
                    <li>Nos esforzamos por mantener la disponibilidad continua de la plataforma, pero no garantizamos un funcionamiento ininterrumpido.</li>
                    <li>No nos hacemos responsables de accesos no autorizados debido a negligencia del usuario.</li>
                </ul>
            </section>
            <hr />

            <section>
                <h2 className="terms-subtitle">9. Resolución de Disputas</h2>
                <p className="terms-text">
                    Las disputas serán resueltas mediante arbitraje, salvo que la legislación local indique lo contrario. La jurisdicción aplicable será Polonia.
                </p>
            </section>
            <hr />

            <section>
                <h2 className="terms-subtitle">10. Modificaciones a los Términos</h2>
                <p className="terms-text">
                    Swapbits puede actualizar estos Términos en cualquier momento. Los cambios se notificarán con al menos 30 días de antelación.
                </p>
            </section>
            <hr />

            <section>
                <h2 className="terms-subtitle">11. Cancelación de Servicios</h2>
                <ul className="terms-list">
                    <li>Los usuarios pueden cerrar su cuenta en cualquier momento, previa liquidación de saldos pendientes.</li>
                    <li>Swapbits puede suspender cuentas por incumplimiento de estos términos.</li>
                </ul>
            </section>
            <hr />

            <section>
                <h2 className="terms-subtitle">12. Información de Contacto</h2>
                <p className="terms-text">
                    Para consultas, soporte o reclamaciones, contáctanos en:
                </p>
                <p className="terms-text">
                    <strong>Correo Electrónico:</strong> <a href="mailto:support@swapbits.io" className="terms-link">support@swapbits.io</a><br />
                    <strong>Dirección:</strong> Bartycka 22B/21A, 00-716 Varsovia, Polonia
                </p>
            </section>
        </div>
    );
};

export default TermsAndConditions;
