import React from "react";
import "../styles/PrivacyPolicy.css";

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <div className="privacy-policy__container">
                <h1 className="privacy-policy__title">Política de Privacidad</h1>
                <p className="privacy-policy__date">
                    Fecha de entrada en vigor: 05/01/2025 <br />
                    Última actualización: 05/01/2025
                </p>
                <p className="privacy-policy__intro">
                    En Swapbits Financial, respetamos tu privacidad y nos comprometemos a proteger tu información personal.
                    Este documento explica cómo recopilamos, usamos, protegemos y compartimos tus datos. Al utilizar nuestros servicios en 
                    <a href="https://www.swapbits.io" className="privacy-policy__link"> www.swapbits.io</a>, aceptas los términos de esta Política de Privacidad.
                </p>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">1. Responsable del Tratamiento</h2>
                    <p className="privacy-policy__text">
                        La entidad responsable del tratamiento de tus datos personales es:
                    </p>
                    <ul className="privacy-policy__list">
                        <li><strong>Nombre Legal:</strong> Swapbits Financial</li>
                        <li><strong>Número de Registro:</strong> 0001132148</li>
                        <li><strong>Número de Licencia VASP:</strong> RDWW-1584</li>
                        <li><strong>Dirección:</strong> Bartycka 22B/21A, 00-716 Varsovia, Polonia</li>
                        <li><strong>Correo Electrónico:</strong> <a href="mailto:privacy@swapbits.io" className="privacy-policy__link">privacy@swapbits.io</a></li>
                    </ul>
                </section>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">2. Información que recopilamos</h2>
                    <h3 className="privacy-policy__subsubtitle">a) Datos proporcionados por el usuario</h3>
                    <ul className="privacy-policy__list">
                        <li>Información personal: Nombre, dirección, correo electrónico, número de teléfono y documentos de identidad.</li>
                        <li>Información financiera: Detalles bancarios o wallets de criptomonedas.</li>
                        <li>Otra información necesaria para cumplir con normativas KYC (Know Your Customer).</li>
                    </ul>
                    <h3 className="privacy-policy__subsubtitle">b) Datos automáticos</h3>
                    <ul className="privacy-policy__list">
                        <li>Dirección IP, tipo de navegador, dispositivo y sistema operativo.</li>
                        <li>Información de uso de la plataforma, como fechas, horarios y actividades realizadas.</li>
                    </ul>
                    <h3 className="privacy-policy__subsubtitle">c) Datos de terceros</h3>
                    <ul className="privacy-policy__list">
                        <li>Datos obtenidos de proveedores externos para verificaciones KYC y AML (Anti-Money Laundering).</li>
                    </ul>
                </section>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">3. Finalidades del Tratamiento</h2>
                    <p className="privacy-policy__text">Tus datos personales serán tratados para:</p>
                    <ul className="privacy-policy__list">
                        <li>Prestación del Servicio: Gestionar tu registro, facilitar transacciones y operar la plataforma.</li>
                        <li>Cumplimiento Legal: Cumplir con normativas AML y KYC.</li>
                        <li>Mejora del Servicio: Optimizar la experiencia del usuario y desarrollar nuevas funcionalidades.</li>
                        <li>Comunicaciones: Enviar notificaciones relacionadas con tu cuenta y, con tu consentimiento, información promocional.</li>
                    </ul>
                </section>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">4. Base Legal para el Tratamiento</h2>
                    <ul className="privacy-policy__list">
                        <li><strong>Ejecución de un Contrato:</strong> Para prestarte los servicios solicitados.</li>
                        <li><strong>Cumplimiento de Obligaciones Legales:</strong> Derivadas de normativas AML/CTF.</li>
                        <li><strong>Consentimiento:</strong> Para comunicaciones comerciales.</li>
                        <li><strong>Interés Legítimo:</strong> Mejorar y proteger nuestros servicios.</li>
                    </ul>
                </section>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">5. Transferencias Internacionales</h2>
                    <p className="privacy-policy__text">
                        Tus datos pueden ser transferidos y tratados fuera del Espacio Económico Europeo (EEE). Garantizamos que estas transferencias cumplen con medidas de seguridad adecuadas, como cláusulas contractuales estándar aprobadas por la Comisión Europea.
                    </p>
                </section>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">6. Derechos del Usuario</h2>
                    <p className="privacy-policy__text">
                        Como titular de los datos, tienes derecho a:
                    </p>
                    <ul className="privacy-policy__list">
                        <li>Acceso: Obtener una copia de tus datos.</li>
                        <li>Rectificación: Corregir información incorrecta.</li>
                        <li>Eliminación: Solicitar la eliminación de tus datos personales.</li>
                        <li>Restricción: Limitar el tratamiento de tus datos.</li>
                        <li>Oposición: Negarte al tratamiento en determinadas circunstancias.</li>
                        <li>Portabilidad: Recibir tus datos en un formato estructurado.</li>
                    </ul>
                    <p className="privacy-policy__text">
                        Para ejercer estos derechos, contáctanos en <a href="mailto:privacy@swapbits.io" className="privacy-policy__link">privacy@swapbits.io</a>, adjuntando una copia de tu documento de identidad.
                    </p>
                </section>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">7. Seguridad de los Datos</h2>
                    <p className="privacy-policy__text">
                        Adoptamos medidas técnicas y organizativas para proteger tus datos, incluyendo:
                    </p>
                    <ul className="privacy-policy__list">
                        <li>Cifrado de datos sensibles.</li>
                        <li>Controles de acceso restrictivos.</li>
                        <li>Monitoreo continuo de la plataforma para prevenir accesos no autorizados.</li>
                    </ul>
                </section>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">8. Conservación de los Datos</h2>
                    <p className="privacy-policy__text">
                        Conservaremos tus datos mientras sea necesario para cumplir con nuestras obligaciones legales, proporcionar los servicios solicitados, resolver disputas o hacer cumplir nuestros acuerdos. Posteriormente, los datos serán eliminados de manera segura.
                    </p>
                </section>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">9. Uso de Cookies</h2>
                    <p className="privacy-policy__text">
                        Usamos cookies para personalizar tu experiencia en la plataforma, analizar el tráfico y comportamiento de los usuarios, y facilitar funcionalidades esenciales del sitio web. Puedes gestionar tus preferencias de cookies desde la configuración de tu navegador. Consulta nuestra <a href="#" className="privacy-policy__link">Política de Cookies</a> para más detalles.
                    </p>
                </section>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">10. Transferencia de Datos a Terceros</h2>
                    <p className="privacy-policy__text">
                        Podemos compartir tu información con proveedores de servicios para verificaciones KYC/AML y procesamiento de pagos, autoridades regulatorias cuando lo exijan las leyes aplicables, y terceros autorizados por el usuario con tu consentimiento explícito.
                    </p>
                </section>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">11. Modificaciones a esta Política</h2>
                    <p className="privacy-policy__text">
                        Nos reservamos el derecho de actualizar esta Política de Privacidad. Notificaremos cualquier cambio significativo a través de nuestro sitio web o correo electrónico.
                    </p>
                </section>

                <section className="privacy-policy__section">
                    <h2 className="privacy-policy__subtitle">12. Información de Contacto</h2>
                    <p className="privacy-policy__text">
                        Para consultas relacionadas con esta Política de Privacidad, puedes contactarnos en:
                    </p>
                    <ul className="privacy-policy__list">
                        <li><strong>Correo Electrónico:</strong> <a href="mailto:privacy@swapbits.io" className="privacy-policy__link">privacy@swapbits.io</a></li>
                        <li><strong>Número de Registro:</strong> 0001132148</li>
                        <li><strong>Número de Licencia VASP:</strong> RDWW-1584</li>
                        <li><strong>Dirección:</strong> Bartycka 22B/21A, 00-716 Varsovia, Polonia</li>
                    </ul>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
